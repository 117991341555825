@import url(https://fonts.googleapis.com/css2?family=Krub:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: 'Krub', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.ant-select-item.ant-select-item-option{
  font-size: 16px !important ;
  padding-top: 6px;
  padding-bottom: 6px;
}
.ant-picker-input{
  margin-left: -10px;
  
  
}
.custom-quill-editor .ql-container {
  border: 1px solid #E1E4EB;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
}
.custom-quill-editor .ql-toolbar.ql-snow{
  border: 1px solid #E1E4EB;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
}
::-webkit-scrollbar-track {
  background: white;
  border-radius: 3px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#a3a3a3, #a3a3a3);
  border-radius: 3px;
}

.popover-delete-confirm .ant-popover-buttons {
  text-align: center;
}


.warning-modal .ant-modal-content {
  border-radius: 8px ;
}

.warning-modal .ant-modal-header {
  border-radius: 10px 10px 0 0 ;
}

.warning-modal .ant-btn{
  border-radius: 32px;
}
.heading{
  color: #47A4EA;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: 'Krub', sans-serif ;
  font-size: 21px;
  padding-left: 10px !important;
  border-left: solid white;
  background-color: white;
}
.heading strong{
  color: #47A4EA;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 21px;
  font-family: 'Krub', sans-serif ;
  font-weight: 600;
}
.paragraph{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  font-family: 'Krub', sans-serif ;
  padding-left: 10px !important;
  border-left: solid white;
  background-color: white;
}
.editor-container{
  width: 100%;
  height: 100%;

}
ql-container ql-snow{
  height: 100%;
}

.active-section{
  border-left: solid #47A4EA;
  background-color: #f1f3f4;
  width: 100%;
}
#quill-editor-scroll-container{
  /* height: 100%;
  min-height: 100%; */
  height: calc(100vh - 410px);
  overflow-y: auto;
}
#quill-editor-container .ql-editor {
  overflow-y: visible; 
  padding: 35px;
}
#quill-editor-container .ql-container {
  height: auto;
  min-height: 100%;
  margin-bottom: 80px;
}


.display-none{
  display: none;
}

dfn{
  background-color: #f0f2f5;
  color: gray;
  font-style: normal;
}
