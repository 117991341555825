.heading{
  color: #47A4EA;
  width: fit-content;
  font-family: 'Krub', sans-serif ;
  font-size: 21px;
  padding-left: 10px !important;
  border-left: solid white;
  background-color: white;
}
.heading strong{
  color: #47A4EA;
  width: fit-content;
  font-size: 21px;
  font-family: 'Krub', sans-serif ;
  font-weight: 600;
}
.paragraph{
  width: fit-content;
  font-size: 14px;
  font-family: 'Krub', sans-serif ;
  padding-left: 10px !important;
  border-left: solid white;
  background-color: white;
}
.editor-container{
  width: 100%;
  height: 100%;

}
ql-container ql-snow{
  height: 100%;
}

.active-section{
  border-left: solid #47A4EA;
  background-color: #f1f3f4;
  width: 100%;
}
#quill-editor-scroll-container{
  /* height: 100%;
  min-height: 100%; */
  height: calc(100vh - 410px);
  overflow-y: auto;
}
#quill-editor-container .ql-editor {
  overflow-y: visible; 
  padding: 35px;
}
#quill-editor-container .ql-container {
  height: auto;
  min-height: 100%;
  margin-bottom: 80px;
}


.display-none{
  display: none;
}

dfn{
  background-color: #f0f2f5;
  color: gray;
  font-style: normal;
}