::-webkit-scrollbar-track {
  background: white;
  border-radius: 3px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#a3a3a3, #a3a3a3);
  border-radius: 3px;
}

.popover-delete-confirm .ant-popover-buttons {
  text-align: center;
}


.warning-modal .ant-modal-content {
  border-radius: 8px ;
}

.warning-modal .ant-modal-header {
  border-radius: 10px 10px 0 0 ;
}

.warning-modal .ant-btn{
  border-radius: 32px;
}